@charset "utf-8";
@use "@/styles/utilities/_animation" as _animation;

$animation-speed-blink: 120ms;

$animation-speed-blink-view: 160ms;

$animation-mv-delay-1: 2000ms;
$animation-mv-delay-2: 2400ms;
$animation-mv-delay-3: 2800ms;
$animation-mv-delay-4: 3200ms;

@include _animation.keyframes(rotate) {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(360deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}


@include _animation.keyframes(blink-visible) {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1.0;
  }

  40% {
    opacity: 0;
  }

  60% {
    opacity: 1.0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1.0;
  }
}

@include _animation.keyframes(blink-hide) {
  0% {
    opacity: 1.0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 1.0;
  }

  60% {
    opacity: 0;
  }

  80% {
    opacity: 1.0;
  }

  100% {
    opacity: 0;
  }
}

@include _animation.keyframes(blink-hover) {
  0% {
    opacity: 1.0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 1.0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1.0;
  }
}


@include _animation.keyframes(glitch-cyan-pc-title) {

  0% {
    left: -1px;
    top: -1px;
  }

  91% {

    left: -1px;
    top: -1px;
  }

  92% {

    top: 0.4px;
    left: -0.2px;
  }

  93% {

    left: .5px;
  }

  94% {
    top: -0.3px;
    left: -0.7px;
  }

  95% {

    left: 0.2px;
  }

  96% {

    top: 1.8px;
    left: -1.2px;
  }

  97% {
    top: -1px;
    left: 0.1px;
  }

  98% {
    top: -0.4px;
    left: -0.9px;
  }

  99% {
    left: 1.2px;
  }

  100% {
    left: -1px;
    top: -1px;
  }

}


@include _animation.keyframes(glitch-cyan-pc-intro) {

  0% {
    left: -0.5px;
    top: -0.5px;
  }

  91% {

    left: -0.5px;
    top: -0.5px;
  }

  92% {

    top: 0.4px;
    left: -0.2px;
  }

  93% {

    left: .5px;
  }

  94% {
    top: -0.3px;
    left: -0.7px;
  }

  95% {

    left: 0.2px;
  }

  96% {

    top: 1.8px;
    left: -1.2px;
  }

  97% {
    top: -1px;
    left: 0.1px;
  }

  98% {
    top: -0.4px;
    left: -0.9px;
  }

  99% {
    left: 1.2px;
  }

  100% {
    left: -0.5px;
    top: -0.5px;
  }

}


@include _animation.keyframes(glitch-cyan-sp) {

  0% {
    left: -0.05px;
    top: -0.05px;
  }

  91% {

    left: -0.05px;
    top: -0.05px;
  }

  92% {

    top: 0.4px;
    left: -0.2px;
  }

  93% {

    left: .5px;
  }

  94% {
    top: -0.3px;
    left: -0.7px;
  }

  95% {

    left: 0.2px;
  }

  96% {

    top: 1.8px;
    left: -1.2px;
  }

  97% {
    top: -1px;
    left: 0.1px;
  }

  98% {
    top: -0.4px;
    left: -0.9px;
  }

  99% {
    left: 1.2px;
  }

  100% {
    left: -0.05px;
    top: -0.05px;
  }

}




@include _animation.keyframes(glitch-magenta-pc-title) {

  0% {
    right: -1px;
    bottom: -1px;
  }

  91% {
    right: -1px;
    bottom: -1px;
  }

  92% {

    bottom: 0.4px;
    right: -0.2px;
  }

  93% {

    right: .5px;
  }

  94% {
    bottom: -0.3px;
    right: -0.7px;
  }

  95% {

    right: 0.2px;
  }

  96% {

    bottom: 1.8px;
    right: -1.2px;
  }

  97% {
    bottom: -1px;
    right: 0.1px;
  }

  98% {
    bottom: -0.4px;
    right: -0.9px;
  }

  99% {
    right: 1.2px;
  }

  100% {
    right: -1px;
    bottom: -1px;
  }

}

@include _animation.keyframes(glitch-magenta-pc-intro) {

  0% {
    right: -2.5px;
    bottom: -0.5px;
  }

  91% {
    right: -2.5px;
    bottom: -0.5px;
  }

  92% {

    bottom: 0.4px;
    right: -0.2px;
  }

  93% {

    right: .5px;
  }

  94% {
    bottom: -0.3px;
    right: -0.7px;
  }

  95% {

    right: 0.2px;
  }

  96% {

    bottom: 1.8px;
    right: -1.2px;
  }

  97% {
    bottom: -1px;
    right: 0.1px;
  }

  98% {
    bottom: -0.4px;
    right: -0.9px;
  }

  99% {
    right: 1.2px;
  }

  100% {
    right: -2.5px;
    bottom: -0.5px;
  }

}


@include _animation.keyframes(glitch-magenta-sp) {

  0% {
    right: -0.05px;
    bottom: -0.05px;
  }

  91% {
    right: -0.05px;
    bottom: -0.05px;
  }

  92% {

    bottom: 0.4px;
    right: -0.2px;
  }

  93% {

    right: .5px;
  }

  94% {
    bottom: -0.3px;
    right: -0.7px;
  }

  95% {

    right: 0.2px;
  }

  96% {

    bottom: 1.8px;
    right: -1.2px;
  }

  97% {
    bottom: -1px;
    right: 0.1px;
  }

  98% {
    bottom: -0.4px;
    right: -0.9px;
  }

  99% {
    right: 1.2px;
  }

  100% {
    right: -0.05px;
    bottom: -0.05px;
  }

}