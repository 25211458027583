@charset "utf-8";
@use '@/styles/commons/_size' as size;
@use '@/styles/utilities/_text' as _text;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto+Sans+JP:wght@500;700&display=swap');

$base-gothic: 'Noto Sans JP', YuGothic, '游ゴシック', 'dnp-shuei-gothic-kin-std',
  'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Arial, Meiryo, sans-serif;
$base-mincho: YuMincho, '游明朝', 'Hiragino Mincho ProN', serif;
$base-sans-serif: 'Lato', sans-serif;
$base-serif: 'Times New Roman';

$base-number: 'Bebas Neue', sans-serif;

$font-family-gothic: $base-gothic;
$font-family-mincho: $base-mincho;
$font-family-sans-serif: $base-sans-serif, $base-gothic;
$font-family-serif: $base-serif, $base-mincho;

$font-family-number: $base-number;

$font-family-en: $base-sans-serif;
$font-w-en-regular: 400;

$font-family-ja: $base-gothic;
$font-w-ja-medium: 500;
$font-w-ja-bold: 700;

$font-s-min: 10px;
$font-s-min-middle: 12px;
$font-s-small: 14px;
$font-s-small-middle: 20px;
$font-s-medium: 24px;
$font-s-medium-middle: 32px;
$font-s-large: 40px;
$font-s-large-middle: 52px;
$font-s-extra_large: 64px;

$font-l_s-min: 0em;
$font-l_s-min-middle: 0.0125em;
$font-l_s-small: 0.025em;
$font-l_s-small-middle: 0.0375em;
$font-l_s-medium: 0.05em;
$font-l_s-medium-middle: 0.0625em;
$font-l_s-large: 0.075em;
$font-l_s-large-middle: 0.0875em;
$font-l_s-extra_large: 0.1em;

$font-l_h-min: 1;
$font-l_h-min-middle: 1.125;
$font-l_h-small: 1.25;
$font-l_h-small-middle: 1.375;
$font-l_h-medium: 1.5;
$font-l_h-medium-middle: 1.625;
$font-l_h-large: 1.75;
$font-l_h-large-middle: 1.875;
$font-l_h-extra_large: 2;

@mixin text-base-ja($font-w, $letter-spacing, $line-height) {
  font-family: $font-family-ja;
  @include _text.text-base($line-height, $font-w, $letter-spacing);
}

@mixin text-base-ja-trim($font-w, $letter-spacing, $line-height) {
  font-family: $font-family-ja;
  @include _text.text-base-trim($line-height, $font-w, $letter-spacing);
}

@mixin text-base-en-trim($font-w, $letter-spacing, $line-height) {
  font-family: $font-family-en;
  @include _text.text-base-trim($line-height, $font-w, $letter-spacing);
}

@mixin text-base-ja-full_width {
  font-variant-east-asian: full-width;
  -moz-font-feature-settings: 'fwid';
  -webkit-font-feature-settings: 'fwid';
  font-feature-settings: 'fwid';
}

@mixin text-base-palt {
  font-feature-settings: 'palt' 1;
}

// @mixin text-en($font-s, $font-w, $letter-spacing, $line-height) {
//   font-family: $font-family-en;
//   font-size: $font-s;
//   font-weight: $font-w;
//   letter-spacing: $letter-spacing;
//   line-height: $line-height;

//   &::before,
//   &::after {
//     content: '';
//     display: block;
//     height: 0;
//     width: 0;
//     margin-block-start: calc((1 - $line-height) * 0.5em);
//   }
// }

// @mixin text-ja($font-s, $font-w, $letter-spacing, $line-height) {
//   font-family: $font-family-ja;
//   font-size: $font-s;
//   font-weight: $font-w;
//   letter-spacing: $letter-spacing;
//   line-height: $line-height;

//   &::before,
//   &::after {
//     content: '';
//     display: block;
//     height: 0;
//     width: 0;
//     margin-block-start: calc((1 - $line-height) * 0.5em);
//   }
// }
