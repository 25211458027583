@use '@/styles/utilities/utilities';
@use '@/styles/commons/commons';

html {
  scroll-behavior: smooth;
  font-family: commons.$font-family-gothic;
}

button {
  cursor: pointer;
}

body {
  overflow-x: hidden;
}

// WalletConnectのモーダルを手前に表示させる
wcm-modal {
  position: relative;
  z-index: 9999;
}
